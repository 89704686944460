import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";


const BlogDetailsDark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        className="insider-post"
        title="Elfonze at BTS 2024"
        paragraph='Redefining IT, Digital and Engineering Services!'
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img w-100 text-center">
                    <img src="/img/elf_insider/bts_2024 _collage.jpg" alt="Elfonze at BTS 2024 Redefining IT, Digitaland Engineering Services!" className="w-50"/>
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                            Elfonze at BTS 2024 Redefining IT, Digital and Engineering Services! 
                          </p>
                          <p>
                            Elfonze made a remarkable impact at the Bengaluru Tech Summit 2024 (November 19-21), showcasing its cutting-edge offerings at a dynamic booth that drew thousands of visitors. From industry leaders and professionals to government bodies, stakeholders and startups, our presence resonated with a diverse and influential audience.
                          </p>
                          <p>
                            This event themed as “UNBOUND,” BTS 2024 spotlighted transformative advancements in Semicon, ESDM, AI, Cybersecurity, Automotive, Space, Logistics, SCM and Sustainability—areas where Elfonze is shaping the future. Our participation not only enhanced customer engagement but also opened doors to exciting collaborations, strengthening our role in driving “Local for Global” innovation.
                          </p>
                          <p>
                            As Karnataka’s flagship tech event, BTS 2024 featured the State’s leadership in technology and Elfonze proudly stood at the forefront. Engaging with global minds and exploring limitless opportunities, we reaffirmed our position as a key player in Karnataka’s technological ecosystem.
                            The journey continues, fueled by innovation, collaboration and the vision to lead in an ever-evolving tech landscape!
                          </p>
                          
                          <div className="share-info">
                            <div className="tags">
                            <a href="#0">#bts2024</a>, 
                            <a href="#0">#unbound</a>,
                            <a href="#0">#localforglobal</a>,
                            <a href="#0">#startup</a>,
                            <a href="#0">#techsummit</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Elfonze at BTS 2024</title>
      <meta key="description" name="description"
        content='Redefining IT, Digitaland Engineering Services!'/>
    </>
  )
}

export default BlogDetailsDark;
